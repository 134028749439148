import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "color"
    }}>{`Color`}</h2>
    <p><em parentName="p">{`Note: The UI Shell does not currently use the Carbon theme tokens; theming options for the shell will be available in the future. All color used in the UI Shell is from the `}<a parentName="em" {...{
          "href": "https://www.ibm.com/design/language/elements/color#specifications"
        }}>{`IBM Design Language palette`}</a>{`.`}</em></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header-panel`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 100`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header-panel`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 80`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__action--active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border-left, border-right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 80`}</td>
        </tr>
      </tbody>
    </table>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.90760869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAABi0lEQVQ4y62R2U7CUBCGG4mJWhQS9n0JSMqN0XhRMPIK5aomPIjPpj4CVzwBibgQrlgSCNhl/OekYBWPaGKTSac9c775/xlF+cen2+0qymAwuM5ms41oNNpMJBLNeDwuiwbO9WQyqafTaRGZTEYPh8N6u92+WiwWmqCORiMql8s2gG8otnBJFnYsFiOAqFAoULFYpFKpRJFIxDZNk+bz+YMAIhm2Wq1mKBQ6Q+EFup5/iUs4OAXoNp/PUyqVcgAnNBfwYDBoQyFB4b0AElEfsXM+ABmVSoXQwIJ9F1AX/1wALQ94Jwodx3nu9XonnHc6nQC67vkDgH0+Q35TrVYZaHtAVkuqqlqGYXwoBLA/Ho8POF+tVlvKYDPgAc16vU6wz7OUA13XfZlOp8ecL5fLLWAul9sANU0TCtdAnuOfFQIU8GZo8mbxbfP8fEuxPi0FwCcoVGVAWNwo5BkycNcMHyeTyZEM6Ldcq9V+vZRDGRAXBRAWTbYJmLD801JeZ7OZdCl+IEPWQLy/Bb4DHM0XGYjotuQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "UI shell right panel example.",
            "title": "UI shell right panel example.",
            "src": "/static/e151200a0dfc87b592a9652a2fd3c594/fb070/right-panel-style-1.png",
            "srcSet": ["/static/e151200a0dfc87b592a9652a2fd3c594/d6747/right-panel-style-1.png 288w", "/static/e151200a0dfc87b592a9652a2fd3c594/09548/right-panel-style-1.png 576w", "/static/e151200a0dfc87b592a9652a2fd3c594/fb070/right-panel-style-1.png 1152w", "/static/e151200a0dfc87b592a9652a2fd3c594/c3e47/right-panel-style-1.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">UI shell switcher example</Caption>
      </Column>
    </Row>
    <h3 {...{
      "id": "context-switcher"
    }}>{`Context switcher`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--switcher__item`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 30`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--switcher__item:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 100-hover`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--switcher__item:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--switcher__item:focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`White`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--switcher__item:active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 80`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--switcher__item:active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--switcher__item-link--selected`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 90`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--switcher__item-link--selected`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--switcher__item:disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 30`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "typography"
    }}>{`Typography`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-size (px/rem)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-weight`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--switcher__item`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SemiBold / 600`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$heading-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "structure"
    }}>{`Structure`}</h2>
    <p>{`The panel spans the full height of the browser and is fixed to the right edge of the window.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px/rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header-panel`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`256 / 16`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--switcher__item`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32 / 2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--switcher__item`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding left, padding right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__action`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height, width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48 / 8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--header__action`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`svg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20 / 1.25`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
      </tbody>
    </table>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "53.30357142857143%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAACYElEQVQoz42Ty08TURSHeZjuoA/amc7QpwydPugDRqZNUCKIhRI0UOuCnRsSFyxwAb7ia+VC7IYN/wD/hGlD0oXvQhdQEcNjYePCNBFj2ul0Osd7pk00uoBJTm5u8t0vv3vOnTYAaDvtOwsTdDj+bDY3N9vJoTvz8/NrOp1u1el0pkmtut3uNVJLLSlTKpXSNEWlrTbbS08gkOb8/hec17vex3FXkfFxXLsmzGQyneTAfqFQAI/HA1arFWw2G9jtdlz3WsIhUrC+sQHDs7MwODkJkXgconNzcHFh4Tky44npTk2Yy+U6CPtWVVUQRVHS6/UyTdMSRVHAMMxrZCpyLUgYeb9crvtjMdliNsuUxVJx8jxMLq/cR8aTiDeF2WwWhR8qlQoIgqAYjUZMqRApsCz7DhlZUUKEUbc/f4FIWFDt+h6wG8yyx8VB8tbtJ8h0Xh/t0IT5fB6vnK9Wq5rQYDD8L2w0InjlwuERxKZnVD4YBj4UkSOjl+HavQePkLmUutlMuLu7e46w238JVSKs/yMUULh1cKjGkskGL4oqH43WIqSXiWdPHyMTv5FqJtzZ2UFhXpIkFKqthCoKGZZ9j0xNUXAo6tbxMfQFB8DU1QWm7m7F5nLB+OLiQ2S8Y2PNhMViEYV7Wg+HBMlkNNaJsGqlybTZ3jetHg5jwo8HBzVHIFClzGYc2i9nfz9MLC3d1d7ixERT+O1rCd9h4ufJyYjAD4TCjFu84giMhHrPR4Oufi8y9UajhzCpV5+Kg44LQpClKD9D036nzxcWV5ZZZAamps72F/z4Xj6VGZ6Z0dbf0No1rBKLF10AAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "UI shell right panel example.",
        "title": "UI shell right panel example.",
        "src": "/static/a962819160b5b2e8a854c0f6109393ce/fb070/right-panel-style-2.png",
        "srcSet": ["/static/a962819160b5b2e8a854c0f6109393ce/d6747/right-panel-style-2.png 288w", "/static/a962819160b5b2e8a854c0f6109393ce/09548/right-panel-style-2.png 576w", "/static/a962819160b5b2e8a854c0f6109393ce/fb070/right-panel-style-2.png 1152w", "/static/a962819160b5b2e8a854c0f6109393ce/fb104/right-panel-style-2.png 1728w", "/static/a962819160b5b2e8a854c0f6109393ce/8fefe/right-panel-style-2.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">
  Structure and spacing measurements for right panel | px | rem.
    </Caption>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      